import { createApp } from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Tabs from "vue3-tabs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
//import VueFriendlyIframe from "vue-friendly-iframe"
import VueSmoothScroll from "vue3-smooth-scroll";
import VueSocialSharing from "vue-social-sharing";
// import VueSocials from "vue-socials";
// import vuesocial from "@growthbunker/vuesocial";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { OhVueIcon, addIcons } from "oh-vue-icons";
import * as FaIcons from "oh-vue-icons/icons/fa";
const Fa = Object.values({ ...FaIcons });
addIcons(...Fa);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vSelect from "vue-select";
import "./styles/main.scss";

createApp(App)
  .use(store)
  .use(router)
  .use(Tabs)
  .use(VueSocialSharing)
  //.use(VueSocials)
  // .use(vuesocial)
  .use(VueSmoothScroll)
  .use(vSelect)
  .component("v-icon", OhVueIcon)
  .mount("#app");
