import { createStore } from "vuex";
import axios from "axios";
import * as d3 from "d3";
import * as d3Array from "d3-array";

const ApiService = axios.create({
  // baseURL:
  //   process.env.NODE_ENV === "production"
  //     ? "https://informe-pais.ine.mx/"
  //     : "http://localhost:1337/",
  // baseURL: "http://localhost:1337/",
  baseURL: "https://informe-pais.ine.mx/",
  headers: {
    "Content-Type": "application/json",
  },
});

function calculateOptions(data: any, final_cross: any) {
  const id_final_cross = `id_${final_cross}`;
  const group_by_id = d3Array.group(data.data, (d: any) => d[id_final_cross]);
  const diferent_x = data.data.length / group_by_id.size;
  let final_answers: any = [];
  group_by_id.forEach(group => {
    final_answers = [
      ...final_answers,
      {
        id: group[0][id_final_cross],
        title: group[0][final_cross] || `Respuesta ${group[0][id_final_cross]}`,
      },
    ];
  });
  return final_answers.sort((a: any, b: any) => a.id - b.id);
}
("12");

export default createStore({
  state: {
    informePaisVariables: {},
    openCategories: false,
    encuciSDURL: "",
    isPercentage: true,
    isMainPercentage: true,
    selectedChart: "map",
    selectedMainTopic: {},
    selectedTopic: {},
    selectedQuestion: "AP4_1",
    selectedMainQuestion: "AP4_1",
    selectedX: { key: "REGION", plural: "Regiones" },
    selectedY: { key: null, values: [] },
    answerOptions: [],
    answerMainOptions: [],
    XOptions: [],
    YOptions: [],
    selectedAnswer: {
      title: "Mucho",
      id: 1,
      default: true,
    },
    adultQuestions: [
      "AP7_3_1",
      "AP7_3_2",
      "AP7_3_3",
      "AP7_3_4",
      "AP7_3_5",
      "AP7_3_6",
      "AP7_3_7",
      "AP7_3_8",
      "AP7_3_9",
      "AP7_3_10",
      "AP7_3_11",
      "AP7_3_12",

      "AP7_4_1",
      "AP7_4_2",
      "AP7_4_3",
      "AP7_4_4",
      "AP7_4_5",
      "AP7_4_6",
      "AP7_4_7",
      "AP7_4_8",
      "AP7_4_9",
      "AP7_4_10",
      "AP7_4_11",
      "AP7_4_12",

      "AP7_5_1",
      "AP7_5_2",
      "AP7_5_3",
      "AP7_5_4",
      "AP7_5_5",

      "AP7_6_1",
      "AP7_6_2",
      "AP7_6_3",
      "AP7_6_4",
      "AP7_6_5",

      "AP7_7_1",
      "AP7_7_2",
      "AP7_7_3",
      "AP7_7_4",
      "AP7_7_5",
      "AP7_7_6",
      "AP7_7_7",
      "AP7_7_8",
      "AP7_7_9",

      "AP7_8",

      "AP7_9",

      "R7_10",

      "AP7_11",

      "AP7_12",

      "AP7_13",

      "AP7_13A",

      "AP8_1_1",
      "AP8_1_2",

      "AP8_1A_1",
      "AP8_1A_2",

      "AP8_2_1",
      "AP8_2_2",
      "AP8_2_3",
      "AP8_2_4",
      "AP8_2_5",
      "AP8_2_6",
      "AP8_2_7",

      "AP8_3_1",
      "AP8_3_2",

      "AP8_3A_1",
      "AP8_3A_2",

      "AP8_4_01",
      "AP8_4_02",
      "AP8_4_03",
      "AP8_4_04",
      "AP8_4_05",
      "AP8_4_06",
      "AP8_4_07",
      "AP8_4_08",
      "AP8_4_09",
      "AP8_4_99",
    ],
    regions: [
      {
        id: "1",
        title: "Aridoamérica Occidental",
        region: "Noroeste",
        states: [
          "Baja California",
          "Baja California Sur",
          "Sonora",
          "Chihuahua",
          "Sinaloa",
          "Durango",
          "Zacatecas",
          "Aguascalientes",
        ],
      },
      {
        id: "2",
        region: "Noreste",
        title: "Aridoamérica Oriental",
        states: ["Coahuila", "Nuevo León", "Tamaulipas", "San Luis Potosí"],
      },
      {
        id: "3",
        region: "Centro Sur",
        title: "Mesoamérica",
        states: ["Veracruz", "Puebla", "Tlaxcala", "Oaxaca", "Guerrero"],
      },
      {
        id: "4",
        region: "Centro",
        title: "Mesoamérica Central",
        states: ["Hidalgo", "México", "Ciudad de México", "Morelos"],
      },
      {
        id: "5",
        region: "Centro Occidente",
        title: "Mesoamérica Occidental",
        states: [
          "Guanajuato",
          "Querétaro",
          "Michoacán",
          "Colima",
          "Jalisco",
          "Nayarit",
        ],
      },
      {
        id: "6",
        region: "Sureste",
        title: "Zona Maya",
        states: ["Tabasco", "Chiapas", "Campeche", "Yucatán", "Quintana Roo"],
      },
    ],
    sections: [
      {
        title: "Intereses y conocimiento sobre asuntos públicos y democracia",
        id: 1,
        color: "#8a205b",
        features: [
          "Sentido de arraigo, pertenencia e identidad",
          "Conocimiento e interés en la política y problemas de la comunidad",
          "Prácticas o hábitos cívicos",
          "Valoración de la democracia",
        ],
        description:
          "Los datos de esta sección miden el sentido de arraigo, pertenencia e identidad; conocimiento e interés en la política y problemas de la comunidad; prácticas o hábitos cívicos y la valoración de la democracia.",
        show_desc: false,
      },
      {
        title: "Creencias, valores y actitudes",
        id: 2,
        color: "#801f7f",
        features: [
          "Confianza interpersonal e institucional",
          "Tolerancia y respeto a la diferencia",
          "Percepción y experiencias de discriminación",
          "Respeto a la legalidad y opiniones sobre la corrupción",
        ],
        description:
          "Incluye los datos sobre confianza interpersonal e institucional; tolerancia y respeto a la diferencia; percepción y experiencias de discriminación; respeto a la legalidad y opiniones sobre la corrupción.",
        show_desc: false,
      },
      {
        title: "Relaciones con individuos, asociaciones y poder público",
        id: 3,
        color: "#5a287f",
        features: [
          "Solidaridad, asociacionismo, cooperación, redes e intermediación",
          "Formas de resolución de conflictos y de relación con la autoridad",
        ],
        description:
          "Dentro de esta temática se buscan identificar las formas en que la ciudadanía se relaciona y cómo se vincula con las autoridades e instituciones de gobierno. Para ello se presentan datos sobre solidaridad, asociacionismo, cooperación, redes e intermediación, formas de resolución de conflictos y de relación con la autoridad.",
        show_desc: false,
      },
      {
        title: "Participación",
        id: 4,
        color: "#b61252",
        features: [
          "Resolución de problemas en su comunidad y en asuntos públicos del país",
          "Participación social: comunitaria, cívica y ciudadana",
        ],
        description:
          "Esta temática se conforma por las formas en que la población participa en la resolución de problemas en su comunidad y en asuntos públicos del país.  Para ello comprende las diferentes formas de participación social: comunitaria, cívica y ciudadana.",
        show_desc: false,
      },
      {
        title: "Delitos electorales",
        id: 5,
        color: "#734473",
        features: [
          "Delitos electorales experimentados directamente",
          "Delitos electorales experimentados por gente cercana a su entorno",
        ],
        description:
          "Esta sección busca conocer las posibles experiencias que hayan tenido las y los ciudadanos respecto a delitos electorales experimentados directamente o por gente cercana a su entorno.",
        show_desc: false,
      },
      {
        title: "Representación",
        id: 6,
        color: "#845ba2",
        features: [
          "Transmisión y materialización de los intereses de la ciudadanía",
          "Representación social y política",
        ],
        description:
          "La sección de representación trata sobre la eficacia percibida de distintos mecanismos institucionales para transmitir y materializar los intereses de la ciudadanía. Incluye distintas formas de representación social y política.",
        show_desc: false,
      },
    ],
    all_questions: [],
    mainQuestions: [],
    questions: [],
    mainQuestionData: [],
    questionData: [],
    mapData: [],
    answers: [
      {
        id: 1,
        title: "Mucho",
      },
      {
        id: 2,
        title: "Algo",
      },
      {
        id: 3,
        title: "Poco",
      },
      {
        id: 4,
        title: "Nada",
      },
    ],
  },
  mutations: {
    SET_MAIN_TOPIC(state, new_topic) {
      state.selectedMainTopic = new_topic;
      //console.log(state.selectedMainTopic);
      //console.log(state.all_questions);
      state.mainQuestions = state.all_questions
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .filter(q => q.PRINCIPAL === "SI")
        .filter(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          q => q.SECCION === state.selectedMainTopic.id + 3
        );
      /*const start = `AP${new_topic.id + 4}_`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.questions = state.all_questions.filter(q =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        q["NEMONICO"].includes(start)
      );*/
    },
    SET_TOPIC(state, new_topic) {
      state.selectedTopic = new_topic;
      state.questions = state.all_questions.filter(q => q);
      const start = `AP${new_topic.id + 3}_`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.questions = state.all_questions.filter(q =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        q["NEMONICO"].includes(start)
      );
    },
    SET_ALL_QUESTIONS(state, all_questions) {
      state.all_questions = all_questions.preguntas;
    },
    SET_MAIN_QUESTION(state, new_question) {
      state.selectedMainQuestion = new_question;
    },
    SET_QUESTION(state, new_question) {
      state.selectedQuestion = new_question;
    },
    SET_PERCENTAGE(state, new_value) {
      state.isPercentage = new_value;
    },
    SET_MAIN_PERCENTAGE(state, new_value) {
      state.isMainPercentage = new_value;
    },
    SET_ANSWER(state, new_answer) {
      // console.log(new_answer);
      state.selectedAnswer = new_answer;
    },
    SET_CHART(state, chart_type) {
      state.selectedChart = chart_type;
    },
    SET_QUESTION_DATA(state, data) {
      const final_x = state.selectedX.key;
      state.XOptions = calculateOptions(data, final_x);
      if (state.selectedY.key) {
        const final_y = state.selectedY.key;
        // console.log(final_y);
        state.YOptions = calculateOptions(data, final_y);
      } else state.YOptions = [];
      state.questionData = data;
    },
    RECALC_ANSWERS(state, data) {
      const nemonic = data.meta.question.NEMONICO;
      const final_answers = calculateOptions(data, nemonic);
      state.answerOptions = final_answers;
      state.selectedAnswer = final_answers.find((answ: any) => answ.id == 1);
    },
    RECALC_MAIN_ANSWERS(state, data) {
      const nemonic = data.meta.question.NEMONICO;
      const final_answers = calculateOptions(data, nemonic);
      state.answerMainOptions = final_answers;
    },
    SET_MAIN_QUESTION_DATA(state, data) {
      state.mainQuestionData = data;
    },
    SET_MAP_DATA(state, data) {
      state.mapData = data;
    },
    SET_SELECTED_X(state, data) {
      state.selectedX = data;
    },
    SET_SELECTED_Y(state, cat) {
      state.selectedY = cat;
    },
  },
  actions: {
    getInformePaisVariables({ commit, dispatch, state }, params) {
      return d3.csv(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vTH8fqqKnXsANCpETEmq2GUrAJHoKBFM_wINuKgIa5OyCTDLG75FSizIE0R8hibaOfG4p72DcUDF7pe/pub?gid=0&single=true&output=csv",
        d => {
          const obj = {};
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          obj[d.Variable] = d.Valor;
          return obj;
        }
      );
    },
    submitPollAnswer({ commit, dispatch, state }, payload) {
      return ApiService.post("/api/v1/poll", payload);
    },
    CHANGE_MAIN_TOPIC({ commit, dispatch, state }, new_topic) {
      // console.log(new_topic);
      commit("SET_MAIN_TOPIC", new_topic);
      dispatch("CHANGE_TOPIC", [new_topic]);
      dispatch("CHANGE_MAIN_QUESTION", state.mainQuestions[0]);
    },
    CHANGE_TOPIC({ commit, dispatch, state }, [new_topic, recalc = false]) {
      commit("SET_TOPIC", new_topic);
      dispatch("CHANGE_QUESTION", [state.questions[0], recalc]);
      //dispatch("GET_MAP_DATA", state.questions[0]["NEMONICO"]);
    },
    GET_METADATA({ commit, dispatch, state }) {
      return new Promise(resolve => {
        ApiService.get("/api/v1/metadata").then(({ data }) => {
          commit("SET_ALL_QUESTIONS", data.result);
          dispatch("CHANGE_MAIN_TOPIC", state.sections[0]);
          dispatch("CHANGE_TOPIC", [state.sections[0]]);
          return resolve(data);
        });
      });
    },
    CHANGE_MAIN_QUESTION({ commit, dispatch, state }, question) {
      const question_id = question["NEMONICO"];
      const payload = {
        id_question: question_id,
      };
      commit("SET_MAIN_QUESTION", question);

      return new Promise(resolve => {
        ApiService.post("/api/v1/question", payload).then(({ data }) => {
          commit("RECALC_ANSWERS", data.result);
          commit("RECALC_MAIN_ANSWERS", data.result);
          commit("SET_MAIN_QUESTION_DATA", data.result);
          return resolve(data);
        });
      });
    },
    CHARGE_CHART({ commit, dispatch, state }, [chart_type, need_data]) {
      commit("SET_CHART", chart_type);
      if (need_data) {
        if (chart_type === "map")
          return dispatch("GET_MAP_DATA", [state.selectedQuestion]);
        else return dispatch("GET_QUESTION_DATA", [state.selectedQuestion]);
      }
    },
    CHANGE_QUESTION({ commit, dispatch, state }, [question, recalc = false]) {
      commit("SET_QUESTION", question);
      // console.log(state.selectedChart);
      if (state.selectedChart === "map")
        return dispatch("GET_MAP_DATA", [question, recalc]);
      else return dispatch("GET_QUESTION_DATA", [question, recalc]);
    },
    GET_MAP_DATA({ commit, state }, [question, recalc = false]) {
      const question_id = question["NEMONICO"];
      const payload = {
        id_question: question_id,
        groupby_x: "REGION",
        groupby_values_x: ["1", "2", "3", "4", "5", "6"],
        //answer: state.selectedAnswer.id
      };
      return new Promise(resolve => {
        ApiService.post("/api/v1/question", payload).then(({ data }) => {
          if (recalc) commit("RECALC_ANSWERS", data.result);
          commit("SET_MAP_DATA", data.result);
          return resolve(data);
        });
      });
    },
    GET_QUESTION_DATA({ commit, state }, [question, recalc]) {
      const question_id = question["NEMONICO"];
      const payload: any = {
        id_question: question_id,
        groupby_x: state.selectedX.key,
        //groupby_values_x: state.selectedX.values,
        groupby_y: state.selectedY.key,
        //groupby_values_y: state.selectedY.values,
      };
      if (state.selectedX.key === "EDAD") {
        if (state.adultQuestions.includes(question_id)) {
          payload.groupby_values_x = [
            "10-19",
            "20-29",
            "30-39",
            "40-49",
            "50-59",
            "60-69",
            "70-79",
            "80-89",
            "90-99",
          ];
        } else {
          payload.groupby_values_x = [
            "10-19",
            "20-29",
            "30-39",
            "40-49",
            "50-59",
            "60-69",
            "70-79",
            "80-89",
            "90-99",
          ];
        }
      } else if (state.selectedX.key === "SEXO") {
        payload.groupby_values_x = ["1", "2"];
      } else if (state.selectedX.key === "EDUCACION") {
        payload.groupby_values_x = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
        ];
      } else if (state.selectedX.key === "REGION") {
        payload.groupby_values_x = ["1", "2", "3", "4", "5", "6"];
      } else if (state.selectedX.key === "COLOR") {
        payload.groupby_values_x = [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "99",
        ];
      } else if (state.selectedX.key === "RELIGION") {
        payload.groupby_values_x = ["1", "2", "3", "9"];
      } else if (state.selectedX.key === "INGRESOS") {
        payload.groupby_values_x = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
        ];
      } else if (state.selectedX.key === "ESTRATO") {
        payload.groupby_values_x = ["1", "2", "3", "4"];
      } else if (state.selectedX.key === "OCUPACION") {
        payload.groupby_values_x = ["1", "2", "3", "4", "5"];
      } else if (state.selectedX.key === "ESTADO") {
        payload.groupby_values_x = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
        ];
      }
      if (state.selectedY.key === "EDAD") {
        if (state.adultQuestions.includes(question_id)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          payload.groupby_values_y = [
            "10-19",
            "20-29",
            "30-39",
            "40-49",
            "50-59",
            "60-69",
            "70-79",
            "80-89",
            "90-99",
          ];
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          payload.groupby_values_y = [
            "10-19",
            "20-29",
            "30-39",
            "40-49",
            "50-59",
            "60-69",
            "70-79",
            "80-89",
            "90-99",
          ];
        }
      } else if (state.selectedY.key === "SEXO") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.groupby_values_y = ["1", "2"];
      } else if (state.selectedY.key === "EDUCACION") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.groupby_values_y = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
        ];
      } else if (state.selectedY.key === "REGION") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.groupby_values_y = ["1", "2", "3", "4", "5", "6"];
      } else if (state.selectedY.key === "COLOR") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.groupby_values_y = [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "99",
        ];
      } else if (state.selectedY.key === "RELIGION") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.groupby_values_y = ["1", "2", "3", "9"];
      } else if (state.selectedY.key === "INGRESOS") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.groupby_values_y = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
        ];
      } else if (state.selectedY.key === "ESTRATO") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.groupby_values_y = ["1", "2", "3", "4"];
      } else if (state.selectedY.key === "OCUPACION") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.groupby_values_y = ["1", "2", "3", "4", "5"];
      } else if (state.selectedY.key === "ESTADO") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.groupby_values_y = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
        ];
      }
      return new Promise(resolve => {
        ApiService.post("/api/v1/question", payload).then(({ data }) => {
          // console.log({ data });
          if (recalc) commit("RECALC_ANSWERS", data.result);
          commit("SET_QUESTION_DATA", data.result);
          return resolve(data);
        });
      });
    },
    ERASE_SELECTEDS({ commit, dispatch, state }, question) {
      const base_empty = { key: null, values: null };
      commit("SET_SELECTED_X", base_empty);
      commit("SET_SELECTED_Y", base_empty);
      const question_id = question["NEMONICO"];
      const payload = {
        id_question: question_id,
      };
      return new Promise(resolve => {
        ApiService.post("/api/v1/question", payload).then(({ data }) => {
          // console.log({ data });
          commit("SET_QUESTION_DATA", data.result);
          return resolve(data);
        });
      });
    },
    CHANGE_SELECTED_XY(
      { commit, dispatch, state },
      [cat_x, cat_y = { key: null, values: null }]
    ) {
      const question = state.selectedQuestion;
      commit("SET_SELECTED_X", cat_x);
      commit("SET_SELECTED_Y", cat_y);
      return new Promise(resolve => {
        const payload = {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          id_question: question["NEMONICO"],
          groupby_x: cat_x.key,
          groupby_values_x: ["0", "1", "2", "3", "4", "5", "6"],
          groupby_y: cat_y.key,
          groupby_values_y: cat_y.key
            ? ["0", "1", "2", "3", "4", "5", "6"]
            : null,
        };
        if (cat_x.key === "EDAD") {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (state.adultQuestions.includes(question["NEMONICO"])) {
            payload.groupby_values_x = [
              "10-19",
              "20-29",
              "30-39",
              "40-49",
              "50-59",
              "60-69",
              "70-79",
              "80-89",
              "90-99",
            ];
          } else {
            payload.groupby_values_x = [
              "10-19",
              "20-29",
              "30-39",
              "40-49",
              "50-59",
              "60-69",
              "70-79",
              "80-89",
              "90-99",
            ];
          }
        } else if (cat_x.key === "SEXO") {
          payload.groupby_values_x = ["1", "2"];
        } else if (cat_x.key === "EDUCACION") {
          payload.groupby_values_x = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
          ];
        } else if (cat_x.key === "REGION") {
          payload.groupby_values_x = ["1", "2", "3", "4", "5", "6"];
        } else if (cat_x.key === "COLOR") {
          payload.groupby_values_x = [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "99",
          ];
        } else if (cat_x.key === "RELIGION") {
          payload.groupby_values_x = ["1", "2", "3", "9"];
        } else if (cat_x.key === "INGRESOS") {
          payload.groupby_values_x = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
          ];
        } else if (cat_x.key === "ESTRATO") {
          payload.groupby_values_x = ["1", "2", "3", "4"];
        } else if (cat_x.key === "OCUPACION") {
          payload.groupby_values_x = ["1", "2", "3", "4", "5"];
        } else if (cat_x.key === "ESTADO") {
          payload.groupby_values_x = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
          ];
        }
        if (cat_y.key === "EDAD") {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (state.adultQuestions.includes(question["NEMONICO"])) {
            payload.groupby_values_y = [
              "10-19",
              "20-29",
              "30-39",
              "40-49",
              "50-59",
              "60-69",
              "70-79",
              "80-89",
              "90-99",
            ];
          } else {
            payload.groupby_values_y = [
              "10-19",
              "20-29",
              "30-39",
              "40-49",
              "50-59",
              "60-69",
              "70-79",
              "80-89",
              "90-99",
            ];
          }
        } else if (cat_y.key === "SEXO") {
          payload.groupby_values_y = ["1", "2"];
        } else if (cat_y.key === "EDUCACION") {
          payload.groupby_values_y = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
          ];
        } else if (cat_y.key === "REGION") {
          payload.groupby_values_y = ["1", "2", "3", "4", "5", "6"];
        } else if (cat_y.key === "COLOR") {
          payload.groupby_values_y = [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "99",
          ];
        } else if (cat_y.key === "RELIGION") {
          payload.groupby_values_y = ["1", "2", "3", "9"];
        } else if (cat_y.key === "INGRESOS") {
          payload.groupby_values_y = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
          ];
        } else if (cat_y.key === "ESTRATO") {
          payload.groupby_values_y = ["1", "2", "3", "4"];
        } else if (cat_y.key === "OCUPACION") {
          payload.groupby_values_y = ["1", "2", "3", "4", "5"];
        } else if (cat_y.key === "ESTADO") {
          payload.groupby_values_y = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
          ];
        }
        //console.log(payload);
        ApiService.post("/api/v1/question", payload).then(({ data }) => {
          commit("SET_QUESTION_DATA", data.result);
          return resolve(data);
        });
      });
    },
  },
  modules: {},
});
